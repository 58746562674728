/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/10/2021
 * @Example
 */

import {types} from "./W39F3020_actions";

const initialState = {
    getDataCboTargetTable:  [],
};

export default function (state = initialState, action = {}) {
    if (action.type === types.W39F3020_GET_CBO_TARGET_TABLE_SUCCESS) {
        return {
            ...state,
            getDataCboTargetTable: action.data
        };
    } else {
        return state;
    }
}
