/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

export const types = {
    W39F3015_EDIT: "W39F3015_EDIT",
    W39F3015_EDIT_COMPUTE_METHOD: "W39F3015_EDIT_COMPUTE_METHOD",
};

export function edit(param, cb) {
    return {
        type: types.W39F3015_EDIT,
        param,
        cb
    }
}

export function editComputeMethod(param, cb) {
    return {
        type: types.W39F3015_EDIT_COMPUTE_METHOD,
        param,
        cb
    }
}