/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/5/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../services/api';
import { types } from './W39F3030_actions';

export default function W39F3030Sagas() {
    return [watchGetDetailData()];
}

export function* getDetailData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3030/load-popup-detail', data.params);
        if (response?.data) {
            data.cb && data.cb(null, response);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log('W393030 - get detail data error');
    }
}

export function* watchGetDetailData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3030_GET_DETAIL_DATA, getDetailData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}