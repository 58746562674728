/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/09/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W39F3035_actions";

export default function W39F3035Sagas() {
  return [
    watchGetDataGrid()
  ];
}

export function* getDataGrid(data) {
  try {
    yield delay(300);
    const response = yield Api.put("/w39f3035/load-gird", data.params);
    if (response && response.data) {
      yield put({
        type: types.W39F3035_GET_DATA_GRID_SUCCESS,
        data: response.data
      });
      data.cb && data.cb(null, response.data);
    } else {
      data.cb && data.cb(response, null);
    }
  } catch (e) {
    console.log("get data grid error", e);
  }
}

export function* watchGetDataGrid() {
  while (true) {
    const watcher = yield takeLatest(types.W39F3035_GET_DATA_GRID, getDataGrid);
    yield take(["LOGOUT", "NETWORK"]);
    yield cancel(watcher);
  }
}