/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W39F3015_actions";

export default function W39F3015Sagas() {
    return [
        watchEdit(),
        watchEditComputeMethod(),
    ];
}

export function* edit(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/edit', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit error', e);
    }
}

export function* watchEdit() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3015_EDIT, edit);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* editComputeMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3015/edit-compute-method', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit compute method error', e);
    }
}

export function* watchEditComputeMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3015_EDIT_COMPUTE_METHOD, editComputeMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}