/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/16/2020
 * @Example
 */
export const types = {
    W39F1030_GET_LOAD_FORM_GRID: "W39F1030_GET_LOAD_FORM_GRID",
    W39F1030_GET_LOAD_FORM_GRID_SUCCESS: "W39F1030_GET_LOAD_FORM_GRID_SUCCESS",
    W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION: "W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION",
    W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION_SUCCESS: "W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION_SUCCESS",
    W39F1030_GET_CBO_APP_CHILD_CRITERION: "W39F1030_GET_CBO_APP_CHILD_CRITERION",
    W39F1030_GET_CBO_APP_CHILD_CRITERION_SUCCESS: "W39F1030_GET_CBO_APP_CHILD_CRITERION_SUCCESS",
    W39F1030_SAVE: "W39F1030_SAVE"

};

export function getLoadFormGrid(params, cb) {
    return {
        type: types.W39F1030_GET_LOAD_FORM_GRID,
        params,
        cb
    }
}

export function getLoadListAppChildCriterions(params, cb) {
    return {
        type: types.W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION,
        params,
        cb
    }
}

export function getCboAppChildCriterion(params, cb) {
    return {
        type: types.W39F1030_GET_CBO_APP_CHILD_CRITERION,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W39F1030_SAVE,
        params,
        cb
    }
}

