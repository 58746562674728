/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 6/5/2021
 * @Example
 */

export const types = {
    W39F3030_GET_DETAIL_DATA: 'W39F3030_GET_DETAIL_DATA',
};

export function getDetailData(params, cb) {
    return {
        type: types.W39F3030_GET_DETAIL_DATA,
        params,
        cb,
    };
}