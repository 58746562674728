/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/23/2022
 * @Example
 */

import Config                            from "../../../config";
import Api                               from '../../../services/api';
import { cancel, put, take, takeLatest } from "redux-saga/effects";
import { types }                         from "./W39F3060_actions";
import { delay }                         from "redux-saga";

export default function W39F3060Sagas() {
    return [
        watchGetTableTarget(),
        watchGetCboGoal(),
        watchGetCboComputeMethod(),
        watchGetCboStatus(),
        watchGetGroupTargets(),
        watchLoadForm(),

        watchSave(),
        watchExportExcel(),
    ];
}

function checkUrlFileName(filename) {
    //if file name had special character return valid data
    const specialChar = filename.search("UTF-8");
    if (specialChar !== -1) {
        const reFileName = filename.slice(specialChar + 7, filename.length);
        return filename = decodeURIComponent(reFileName);
    }
    return filename; // else return original data
}

export function* getTableTarget(data) {
    try {
        yield delay(300);
        const response = yield Api.get('/w39f3001/load-table-target');
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get table target error');
    }

}

export function* watchGetTableTarget() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_GET_TABLE_TARGET, getTableTarget);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getGroupTargets(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3001/load-group-target', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get group targets error');
    }

}

export function* watchGetGroupTargets() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_GET_GROUP_TARGETS, getGroupTargets);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboGoal(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3060/load-goal', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F3060_GET_CBO_GOAL_SUCCESS, data: response.data  });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('getCboGoal error');
    }

}

export function* watchGetCboGoal() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_GET_CBO_GOAL, getCboGoal);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboComputeMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/get-compute-method', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F3060_GET_CBO_COMPUTE_METHOD_SUCCESS, data: response.data  });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('getComputeMethod error');
    }

}

export function* watchGetCboComputeMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_GET_CBO_COMPUTE_METHOD, getCboComputeMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3060/get-cbo-status', data.params);
        if (response && response.data) {
            yield put({ type: types.W39F3060_GET_CBO_STATUS_SUCCESS, data: response.data  });
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('getCboStatus error');
    }

}

export function* watchGetCboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_GET_CBO_STATUS, getCboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* loadForm(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3060/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('loadForm error');
    }

}

export function* watchLoadForm() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_LOAD_FORM, loadForm);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* save(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w39f3060/save', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        } else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('save data error');
    }

}

export function* watchSave() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_SAVE, save);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* exportExcel(data) {
    try {
        yield delay(300);
        let response = yield Api.putExport('/w39f3060/excel-export', data.params);
        if (response && response.ok) {
            let _res = null;
            if (response.type === "json") {
                _res = yield response.data.json();
                if (_res && _res.data) {
                    data.cb && data.cb(null, _res.data);
                } else {
                    data.cb && data.cb(_res, null)
                }
            } else {
                const content = yield response.data.blob();
                const datetime = new Date().getTime();
                const _fileName = response.filename ? checkUrlFileName(response.filename) : "data-export-hr-" + datetime + '.xls';
                _res = {
                    fileName: _fileName,
                    fileExt: Config.helpers.getExtFile(_fileName),
                    URL: URL.createObjectURL(content),
                };
                if (_res && _res.URL) {
                    data.cb && data.cb(null, _res);
                } else {
                    data.cb && data.cb(_res, null);
                }
            }
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('export excel error');
    }

}

export function* watchExportExcel() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3060_EXPORT_EXCEL, exportExcel);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}


