/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 4/20/2021
 * @Example
 */

export const types = {
    W39F3010_LOAD_STATUS: "W39F3010_LOAD_STATUS",
    W39F3010_LOAD_GRID: "W39F3010_LOAD_GRID",
    W39F3010_DELETE: "W39F3010_DELETE",
    W39F3010_GET_COMBO_GOAL_TABLE: "W39F3010_GET_COMBO_GOAL_TABLE",
};

export function loadStatus(params, cb) {
    return {
        type: types.W39F3010_LOAD_STATUS,
        params,
        cb,
    };
}

export function loadGrid(params, cb) {
    return {
        type: types.W39F3010_LOAD_GRID,
        params,
        cb,
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W39F3010_DELETE,
        params,
        cb,
    };
}

export function getComboGoalTable(cb) {
    return {
        type: types.W39F3010_GET_COMBO_GOAL_TABLE,
        cb
    }
}