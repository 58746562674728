/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeLatest } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W39F3016_actions";

export default function W39F3016Sagas() {
    return [
        watchGetData(),
        watchEditValue(),
    ];
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/load-form-w39f2016', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data error', e);
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3016_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* editValue(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3016/edit-value', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('edit value error', e);
    }
}

export function* watchEditValue() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3016_EDIT_VALUE, editValue);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}