/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/4/2021
 * @Example
 */

import { delay } from 'redux-saga';
import { cancel, take, takeLatest } from 'redux-saga/effects';
import Api from '../../../services/api';
import { types } from './W39F6000_actions';

export default function W39F6000Sagas() {
    return [
        watchGetComments(),
        watchAddComment(),
        watchEditComment()
    ];
}

export function* getComments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/comment/load-popup-cmt', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('get comments error');
    }
}

export function* watchGetComments() {
    while (true) {
        const watcher = yield takeLatest(types.GET_COMMENTS, getComments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* addComment(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/comment/add', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('add comment error');
    }
}

export function* watchAddComment() {
    while (true) {
        const watcher = yield takeLatest(types.ADD_COMMENT, addComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* editComment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/comment/edit', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        }
        else {
            data.cb && data.cb(response, null);
        }
    }
    catch (e) {
        console.log('edit comment error');
    }
}

export function* watchEditComment() {
    while (true) {
        const watcher = yield takeLatest(types.EDIT_COMMENT, editComment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}