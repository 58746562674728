/**
 * @copyright 2021 @ DigiNet
 * @author QUOCUY
 * @create 4/20/2021
 * @Example
 */

export const types = {
    W39F3011_LOAD_POPUP: "W39F3011_LOAD_POPUP",
    W39F3011_DELETE_ROW: "W39F3011_DELETE_ROW",
    W39F3011_SAVE: "W39F3011_SAVE",
    W39F3011_BTN_COUNT_ACTION: "W39F3011_BTN_COUNT_ACTION"
};

export function loadPopup(params, cb) {
    return {
        type: types.W39F3011_LOAD_POPUP,
        params,
        cb,
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W39F3011_DELETE_ROW,
        params,
        cb,
    };
}

export function save(params, cb) {
    return {
        type: types.W39F3011_SAVE,
        params,
        cb,
    };
}

export function btnCountAction(params, cb) {
    return {
        type: types.W39F3011_BTN_COUNT_ACTION,
        params,
        cb,
    };
}


