/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/1/2021
 * @Example 
 */
export const types = {
    W39F3001_GET_TABLE_TARGET: "W39F3001_GET_TABLE_TARGET",
    W39F3001_GET_GROUP_TARGET: "W39F3001_GET_GROUP_TARGET",
    W39F3001_GET_GROUP_TARGET_SUCCESS: "W39F3001_GET_GROUP_TARGET_SUCCESS",
    W39F3001_GET_CBO_EMPLOYEE: "W39F3001_GET_CBO_EMPLOYEE",
    W39F3001_GET_CBO_GOAL_NAME: "W39F3001_GET_CBO_GOAL_NAME",
    W39F3001_SAVE: "W39F3001_SAVE",
    W39F3001_GET_CAPTIONS: "W39F3001_GET_CAPTIONS",
    W39F3001_GET_CBO_CONCURRENT: "W39F3001_GET_CBO_CONCURRENT",
    W39F3001_GET_CHECK_EMPLOYEE_CONCURRENT: "W39F3001_GET_CHECK_EMPLOYEE_CONCURRENT",
    W39F3001_GET_COEFFOBJECTIVE: "W39F3001_GET_COEFFOBJECTIVE",
};

export function getTableTarget(cb) {
    return {
        type: types.W39F3001_GET_TABLE_TARGET,
        cb
    }
}

export function getCoeffObjectives(params, cb) {
    return {
        type: types.W39F3001_GET_COEFFOBJECTIVE,
        params,
        cb
    }
}

export function getCaptions(cb) {
    return {
        type: types.W39F3001_GET_CAPTIONS,
        cb
    }
}

export function getGroupTarget(params,cb) {
    return {
        type: types.W39F3001_GET_GROUP_TARGET,
        params,
        cb
    }
}

export function getCboEmployees(params, cb) {
    return{
        type: types.W39F3001_GET_CBO_EMPLOYEE,
        params,
        cb
    }
}

export function getCboGoalName(params, cb) {
    return {
        type: types.W39F3001_GET_CBO_GOAL_NAME,
        params,
        cb
    }
}

export function getCboConcurrent(params, cb) {
    return {
        type: types.W39F3001_GET_CBO_CONCURRENT,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W39F3001_SAVE,
        params,
        cb
    }
}

export function getCheckEmployeeConcurrent(params, cb) {
    return {
        type: types.W39F3001_GET_CHECK_EMPLOYEE_CONCURRENT,
        params,
        cb
    }
}