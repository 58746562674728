/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/4/2021
 * @Example
 */

export const types = {
    GET_COMMENTS: 'GET_COMMENTS',
    ADD_COMMENT: 'ADD_COMMENT',
    EDIT_COMMENT: 'EDIT_COMMENT',
};

export function getComments(params, cb) {
    return {
        type: types.GET_COMMENTS,
        params,
        cb
    }
}

export function addComment(params, cb) {
    return {
        type: types.ADD_COMMENT,
        params,
        cb
    }
}

export function editComment(params, cb) {
    return {
        type: types.EDIT_COMMENT,
        params,
        cb
    }
}