/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

export const types = {
    W39F3000_GET_COMBO_STATUS_GOAL: "W39F3000_GET_COMBO_STATUS_GOAL",
    W39F3000_GET_COMBO_STATUS_NEWS: "W39F3000_GET_COMBO_STATUS_NEWS",
    W39F3000_GET_DATA: "W39F3000_GET_DATA",
    W39F3000_GET_DATA_SUCCESS: "W39F3000_GET_DATA_SUCCESS",
    W39F3000_GET_TARGET_RESULT: "W39F3000_GET_TARGET_RESULT",
    W39F3000_FINISH_GOAL_PLAN: "W39F3000_FINISH_GOAL_PLAN",
    W39F3000_GET_COMBO_GOAL_TABLE: "W39F3000_GET_COMBO_GOAL_TABLE",
    W39F3000_GET_COMBO_STATUS_GOAL_PLAN: "W39F3000_GET_COMBO_STATUS_GOAL_PLAN",
    W39F3000_GET_DETAIL_DATA: "W39F3000_GET_DETAIL_DATA",
    W39F3000_GET_DETAIL_DATA_SUCCESS: "W39F3000_GET_DETAIL_DATA_SUCCESS",
    W39F3000_SET_CURRENT_EMPLOYEE: "W39F3000_SET_CURRENT_EMPLOYEE",
    W39F3000_SET_CURRENT_EMPLOYEE_SUCCESS: "W39F3000_SET_CURRENT_EMPLOYEE_SUCCESS",
    W39F3000_SET_CURRENT_ORG: "W39F3000_SET_CURRENT_ORG",
    W39F3000_SET_CURRENT_ORG_SUCCESS: "W39F3000_SET_CURRENT_ORG_SUCCESS",
    W39F3000_SET_CURRENT_TAB: "W39F3000_SET_CURRENT_TAB",
    W39F3000_SET_CURRENT_TAB_SUCCESS: "W39F3000_SET_CURRENT_TAB_SUCCESS",
    W39F3000_SAVE_STANDARD_APPROVAL: "W39F3000_SAVE_STANDARD_APPROVAL",
    W39F3000_GET_SELECT_ADD_FORM: "W39F3000_GET_SELECT_ADD_FORM",
};

export function getSelectAddForm(cb) {
    return {
        type: types.W39F3000_GET_SELECT_ADD_FORM,
        cb
    }
}

export function setCurrentEmployee(param, cb) {
    return {
        type: types.W39F3000_SET_CURRENT_EMPLOYEE,
        param,
        cb
    }
}

export function setCurrentOrg(param, cb) {
    return {
        type: types.W39F3000_SET_CURRENT_ORG,
        param,
        cb
    }
}

export function setCurrentTab(param, cb) {
    return {
        type: types.W39F3000_SET_CURRENT_TAB,
        param,
        cb
    }
}

export function getComboStatusGoal(param, cb) {
    return {
        type: types.W39F3000_GET_COMBO_STATUS_GOAL,
        param,
        cb
    }
}

export function getComboStatusNews(param, cb) {
    return {
        type: types.W39F3000_GET_COMBO_STATUS_NEWS,
        param,
        cb
    }
}

export function getData(param, cb) {
    return {
        type: types.W39F3000_GET_DATA,
        param,
        cb
    }
}

export function getTargetResult(param, cb) {
    return {
        type: types.W39F3000_GET_TARGET_RESULT,
        param,
        cb
    }
}

export function finishGoalPlan(param, cb) {
    return {
        type: types.W39F3000_FINISH_GOAL_PLAN,
        param,
        cb
    }
}

export function getComboGoalTable(cb) {
    return {
        type: types.W39F3000_GET_COMBO_GOAL_TABLE,
        cb
    }
}

export function getComboStatusGoalPlan(param, cb) {
    return {
        type: types.W39F3000_GET_COMBO_STATUS_GOAL_PLAN,
        param,
        cb
    }
}

export function getDetailData(param, cb) {
    return {
        type: types.W39F3000_GET_DETAIL_DATA,
        param,
        cb
    }
}

export function saveStandardApproval(param, cb) {
    return {
        type: types.W39F3000_SAVE_STANDARD_APPROVAL,
        param,
        cb
    }
}