/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 23/3/2021
 * @Example
 */

import { delay } from "redux-saga";
import { cancel, take, takeEvery, takeLatest, put } from "redux-saga/effects";
import Api from '../../../services/api';
import { types } from "./W39F3002_actions";

export default function W39F3002Sagas() {
    return [
        watchGetComboStatus(),
        watchGetData(),
        watchGetComboComputeMethod(),
        watchUpdateGoalName(),
        watchUpdateGoalDes(),
        watchUpdateGoalStatus(),
        watchUpdateGoalDeadline(),
        watchGetDetailData(),
        watchGetAttachments(),
        watchGetTotalComments(),
        watchGetPopupData(),
        watchUpdateDisplayOrder(),
        watchUpdateProportion(),
        watchAddAttachments(),
        watchRemoveAttachment(),
        watchAddResult(),
        watchUpdateDetail(),
        watchGetObjectRef(),
        watchUpdateObjectRef(),
        watchCheckProportion(),
        watchCheckStatus(),
        watchGetCboConcurrent(),
        watchUpdate(),
        watchCheckEmployeeConcurrent(),
        watchUpdateCoeffObjective(),
    ];
}

export function* getComboStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/get-cbo-status', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get combo status error', e);
    }
}

export function* watchGetComboStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_COMBO_STATUS, getComboStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getComboComputeMethod(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/get-compute-method', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get combo compute method error', e);
    }
}

export function* watchGetComboComputeMethod() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_COMBO_COMPUTE_METHOD, getComboComputeMethod);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/load-master', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get data error');
    }
}

export function* watchGetData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_DATA, getData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getDetailData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/load-master', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get detail data error');
    }
}

export function* watchGetDetailData() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_GET_DETAIL_DATA, getDetailData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateGoalName(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-name-target', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update goal name error');
    }
}

export function* watchUpdateGoalName() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_GOAL_NAME, updateGoalName);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateGoalDes(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-describe-target', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update goal description error');
    }
}

export function* watchUpdateGoalDes() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_GOAL_DESCRIPTION, updateGoalDes);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateGoalStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-status', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update goal description error');
    }
}

export function* watchUpdateGoalStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_GOAL_STATUS, updateGoalStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateGoalDeadline(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-deadline', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update goal deadline error');
    }
}

export function* watchUpdateGoalDeadline() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_GOAL_DEADLINE, updateGoalDeadline);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getAttachments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/get-attachment-by-key', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get attachments error');
    }
}

export function* watchGetAttachments() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_GET_ATTACHMENTS, getAttachments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getTotalComments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/comment/count', data.param);
        if (response && (response.data || response.data > -1)) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get total comments error');
    }
}

export function* watchGetTotalComments() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_GET_TOTAL_COMMENTS, getTotalComments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getPopupData(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/load-popup', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get popup data error');
    }
}

export function* watchGetPopupData() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_POPUP_DATA, getPopupData);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateDisplayOrder(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-order-result', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update display order error');
    }
}

export function* watchUpdateDisplayOrder() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_UPDATE_DISPLAY_ORDER, updateDisplayOrder);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateProportion(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-number', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update proportion error');
    }
}

export function* watchUpdateProportion() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_UPDATE_PROPORTION, updateProportion);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* addAttachments(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/add-attachment', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add attachments error');
    }
}

export function* watchAddAttachments() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_ADD_ATTACHMENTS, addAttachments);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* removeAttachment(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/attachment/delete-attachment', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('remove attachment error');
    }
}

export function* watchRemoveAttachment() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_REMOVE_ATTACHMENT, removeAttachment);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* addResult(data) {
    try {
        yield delay(300);
        const response = yield Api.post('/w39f3002/save', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('add result error');
    }
}

export function* watchAddResult() {
    while (true) {
        const watcher = yield takeEvery(types.W39F3002_ADD_RESULT, addResult);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateDetailInfo(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-info-detail', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update detail info error');
    }
}

export function* watchUpdateDetail() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_DETAIL_INFO, updateDetailInfo);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getObjectRef(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/load-ref-id', data.params);
        if (response && response.data) {
            yield put({type: types.W39F3002_GET_OBJECT_REF_SUCCESS, data: response.data});
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get object ref error');
    }
}

export function* watchGetObjectRef() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_OBJECT_REF, getObjectRef);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateObjectRef(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update object ref error');
    }
}

export function* watchUpdateObjectRef() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_OBJECT_REF, updateObjectRef);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* checkProportion(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/check-proportion', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check proportion error');
    }
}

export function* watchCheckProportion() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_CHECK_PROPORTION, checkProportion);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* checkStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/check-status', data.param);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check status error');
    }
}

export function* watchCheckStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_CHECK_STATUS, checkStatus);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCboConcurrent(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3000/load-form', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('get cbo concurrent error');
    }
}

export function* watchGetCboConcurrent() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_CBO_CONCURRENT, getCboConcurrent);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}



export function* update(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update-cbo-concurrent', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update error');
    }
}

export function* watchUpdate() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE, update);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* getCheckEmployeeConcurrent(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3001/check-employee-concurrent', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('check employee concurrent error');
    }
}

export function* watchCheckEmployeeConcurrent() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_GET_CHECK_EMPLOYEE_CONCURRENT, getCheckEmployeeConcurrent);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}

export function* updateCoeffObjective(data) {
    try {
        yield delay(300);
        const response = yield Api.put('/w39f3002/update', data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data)
        }
        else {
            data.cb && data.cb(response, null)
        }
    }
    catch (e) {
        console.log('update coeffObjective error');
    }
}

export function* watchUpdateCoeffObjective() {
    while (true) {
        const watcher = yield takeLatest(types.W39F3002_UPDATE_COEFFOBJECTIVE, updateCoeffObjective);
        yield take(['LOGOUT', 'NETWORK']);
        yield cancel(watcher);
    }
}