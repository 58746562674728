import { types } from "./W39F3002_actions";

const initialState = {
    dataDynamicRef: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F3002_GET_OBJECT_REF_SUCCESS:
            return {
                ...state,
                dataDynamicRef: action.data
            };
        default:
            return state;
    }
}