/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/23/2022
 * @Example
 */

import { types } from "./W39F3060_actions";

const initialState = {
    dataCboGoals: [],
    dataCboComputeMethods: [],
    dataCboStatus: [],
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F3060_GET_CBO_GOAL_SUCCESS:
            return { ...state, dataCboGoals: action.data };
        case types.W39F3060_GET_CBO_COMPUTE_METHOD_SUCCESS:
            return { ...state, dataCboComputeMethods: action.data };
        case types.W39F3060_GET_CBO_STATUS_SUCCESS:
            return { ...state, dataCboStatus: action.data };
        default:
            return state;
    }
}
