/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 14/4/2021
 * @Example
 */

export const types = {
    W39F3016_GET_DATA: "W39F3016_GET_DATA",
    W39F3016_EDIT_VALUE: "W39F3016_EDIT_VALUE",
};

export function getData(param, cb) {
    return {
        type: types.W39F3016_GET_DATA,
        param,
        cb
    }
}

export function editValue(param, cb) {
    return {
        type: types.W39F3016_EDIT_VALUE,
        param,
        cb
    }
}