/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 9/23/2022
 * @Example
 */
export const types = {
    W39F3060_GET_TABLE_TARGET: "W39F3060_GET_TABLE_TARGET",
    W39F3060_GET_GROUP_TARGETS: "W39F3060_GET_GROUP_TARGETS",
    W39F3060_GET_CBO_GOAL: "W39F3060_GET_CBO_GOAL",
    W39F3060_GET_CBO_GOAL_SUCCESS: "W39F3060_GET_CBO_GOAL_SUCCESS",
    W39F3060_GET_CBO_COMPUTE_METHOD: "W39F3060_GET_CBO_COMPUTE_METHOD",
    W39F3060_GET_CBO_COMPUTE_METHOD_SUCCESS: "W39F3060_GET_CBO_COMPUTE_METHOD_SUCCESS",
    W39F3060_GET_CBO_STATUS: "W39F3060_GET_CBO_STATUS",
    W39F3060_GET_CBO_STATUS_SUCCESS: "W39F3060_GET_CBO_STATUS_SUCCESS",
    W39F3060_LOAD_FORM: "W39F3060_LOAD_FORM",

    W39F3060_SAVE: "W39F3060_SAVE",
    W39F3060_EXPORT_EXCEL: "W39F3060_EXPORT_EXCEL",
};

export function getTableTarget(cb) {
    return {
        type: types.W39F3060_GET_TABLE_TARGET,
        cb
    }
}

export function getGroupTargets(params, cb) {
    return {
        type: types.W39F3060_GET_GROUP_TARGETS,
        params,
        cb
    }
}

export function getCboComputeMethods(params,cb) {
    return {
        type: types.W39F3060_GET_CBO_COMPUTE_METHOD,
        params,
        cb
    }
}

export function getCboStatus(params,cb) {
    return {
        type: types.W39F3060_GET_CBO_STATUS,
        params,
        cb
    }
}

export function getCboGoals(params,cb) {
    return {
        type: types.W39F3060_GET_CBO_GOAL,
        params,
        cb
    }
}

export function loadForm(params,cb) {
    return {
        type: types.W39F3060_LOAD_FORM,
        params,
        cb
    }
}

export function save(params, cb) {
    return {
        type: types.W39F3060_SAVE,
        params,
        cb
    }
}

export function exportExcel(params, cb) {
    return {
        type: types.W39F3060_EXPORT_EXCEL,
        params,
        cb
    }
}
