/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 5/4/2021
 * @Example
 */

export const types = {
    W39F3002_GET_DATA: "W39F3002_GET_DATA",
    W39F3002_GET_DETAIL_DATA: "W39F3002_GET_DETAIL_DATA",
    W39F3002_GET_COMBO_STATUS: "W39F3002_GET_COMBO_STATUS",
    W39F3002_GET_COMBO_COMPUTE_METHOD: "W39F3002_GET_COMBO_COMPUTE_METHOD",
    W39F3002_UPDATE_GOAL_NAME: "W39F3002_UPDATE_GOAL_NAME",
    W39F3002_UPDATE_GOAL_DESCRIPTION: "W39F3002_UPDATE_GOAL_DESCRIPTION",
    W39F3002_UPDATE_GOAL_STATUS: "W39F3002_UPDATE_GOAL_STATUS",
    W39F3002_UPDATE_GOAL_DEADLINE: "W39F3002_UPDATE_GOAL_DEADLINE",
    W39F3002_GET_ATTACHMENTS: "W39F3002_GET_ATTACHMENTS",
    W39F3002_GET_TOTAL_COMMENTS: "W39F3002_GET_TOTAL_COMMENTS",
    W39F3002_GET_POPUP_DATA: "W39F3002_GET_POPUP_DATA",
    W39F3002_UPDATE_DISPLAY_ORDER: "W39F3002_UPDATE_DISPLAY_ORDER",
    W39F3002_UPDATE_PROPORTION: "W39F3002_UPDATE_PROPORTION",
    W39F3002_ADD_ATTACHMENTS: "W39F3002_ADD_ATTACHMENTS",
    W39F3002_REMOVE_ATTACHMENT: "W39F3002_REMOVE_ATTACHMENT",
    W39F3002_ADD_RESULT: "W39F3002_ADD_RESULT",
    W39F3002_UPDATE_DETAIL_INFO: "W39F3002_UPDATE_DETAIL_INFO",
    W39F3002_GET_OBJECT_REF: "W39F3002_GET_OBJECT_REF",
    W39F3002_GET_OBJECT_REF_SUCCESS: "W39F3002_GET_OBJECT_REF_SUCCESS",
    W39F3002_UPDATE_OBJECT_REF: "W39F3002_UPDATE_OBJECT_REF",
    W39F3002_CHECK_PROPORTION: "W39F3002_CHECK_PROPORTION",
    W39F3002_CHECK_STATUS: "W39F3002_CHECK_STATUS",
    W39F3002_GET_CBO_CONCURRENT: "W39F3002_GET_CBO_CONCURRENT",
    W39F3002_UPDATE: "W39F3002_UPDATE",
    W39F3002_GET_CHECK_EMPLOYEE_CONCURRENT: "W39F3002_GET_CHECK_EMPLOYEE_CONCURRENT",
    W39F3002_UPDATE_COEFFOBJECTIVE: "W39F3002_UPDATE_COEFFOBJECTIVE",
};

export function getComboStatus(param, cb) {
    return {
        type: types.W39F3002_GET_COMBO_STATUS,
        param,
        cb
    }
}

export function getComboComputeMethod(param, cb) {
    return {
        type: types.W39F3002_GET_COMBO_COMPUTE_METHOD,
        param,
        cb
    }
}

export function getData(param, cb) {
    return {
        type: types.W39F3002_GET_DATA,
        param,
        cb
    }
}

export function getDetailData(param, cb) {
    return {
        type: types.W39F3002_GET_DETAIL_DATA,
        param,
        cb
    }
}

export function updateGoalName(param, cb) {
    return {
        type: types.W39F3002_UPDATE_GOAL_NAME,
        param,
        cb
    }
}

export function updateGoalDes(param, cb) {
    return {
        type: types.W39F3002_UPDATE_GOAL_DESCRIPTION,
        param,
        cb
    }
}

export function updateGoalStatus(param, cb) {
    return {
        type: types.W39F3002_UPDATE_GOAL_STATUS,
        param,
        cb
    }
}

export function updateGoalDeadline(param, cb) {
    return {
        type: types.W39F3002_UPDATE_GOAL_DEADLINE,
        param,
        cb
    }
}

export function getAttachments(param, cb) {
    return {
        type: types.W39F3002_GET_ATTACHMENTS,
        param,
        cb
    }
}

export function getTotalComments(param, cb) {
    return {
        type: types.W39F3002_GET_TOTAL_COMMENTS,
        param,
        cb
    }
}


export function getPopupData(param, cb) {
    return {
        type: types.W39F3002_GET_POPUP_DATA,
        param,
        cb
    }
}

export function updateDisplayOrder(param, cb) {
    return {
        type: types.W39F3002_UPDATE_DISPLAY_ORDER,
        param,
        cb
    }
}

export function updateProportion(param, cb) {
    return {
        type: types.W39F3002_UPDATE_PROPORTION,
        param,
        cb
    }
}

export function addAttachments(param, cb) {
    return {
        type: types.W39F3002_ADD_ATTACHMENTS,
        param,
        cb
    }
}

export function removeAttachment(param, cb) {
    return {
        type: types.W39F3002_REMOVE_ATTACHMENT,
        param,
        cb
    }
}

export function addResult(param, cb) {
    return {
        type: types.W39F3002_ADD_RESULT,
        param,
        cb
    }
}

export function updateDetailInfo(param, cb) {
    return {
        type: types.W39F3002_UPDATE_DETAIL_INFO,
        param,
        cb
    }
}

export function getObjectRef(params, cb) {
    return {
        type: types.W39F3002_GET_OBJECT_REF,
        params,
        cb
    }
}

export function updateObjectRef(param, cb) {
    return {
        type: types.W39F3002_UPDATE_OBJECT_REF,
        param,
        cb
    }
}

export function checkProportion(param, cb) {
    return {
        type: types.W39F3002_CHECK_PROPORTION,
        param,
        cb
    }
}

export function checkStatus(param, cb) {
    return {
        type: types.W39F3002_CHECK_STATUS,
        param,
        cb
    }
}

export function getCboConcurrent(params, cb) {
    return {
        type: types.W39F3002_GET_CBO_CONCURRENT,
        params,
        cb
    }
}


export function update(params, cb) {
    return {
        type: types.W39F3002_UPDATE,
        params,
        cb
    }
}

export function getCheckEmployeeConcurrent(params, cb) {
    return {
        type: types.W39F3002_GET_CHECK_EMPLOYEE_CONCURRENT,
        params,
        cb
    }
}

export function updateCoeffObjective(params, cb) {
    return {
        type: types.W39F3002_UPDATE_COEFFOBJECTIVE,
        params,
        cb
    }
}

export function loadDynamicRef(params, cb) {
    return {
        type: types.W39F3002_DYNAMIC_REF,
        params,
        cb
    }
}