/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 4/10/2021
 * @Example
 */
export const types = {
    W39F3020_GET_CBO_TARGET_TABLE: "W39F3020_GET_CBO_TARGET_TABLE",
    W39F3020_GET_CBO_TARGET_TABLE_SUCCESS: "W39F3020_GET_CBO_TARGET_TABLE_SUCCESS",
    W39F3020_GET_HANDEL_BROWSING: "W39F3020_GET_HANDEL_BROWSING",
    W39F3020_GET_DATA_GRID: "W39F3020_GET_DATA_GRID",
};

export function getCboTargetTable(cb) {
    return {
        type: types.W39F3020_GET_CBO_TARGET_TABLE,
        cb
    }
}

export function getHandleBrowsing(params, cb) {
    return {
        type: types.W39F3020_GET_HANDEL_BROWSING,
        params,
        cb
    }
}

export function getDataGrid(params, cb) {
    return {
        type: types.W39F3020_GET_DATA_GRID,
        params,
        cb
    }
}
