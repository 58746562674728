/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 7/16/2020
 * @Example
 */

import { types } from "./W39F1030_actions";

const initialState = {
    getLoadFormGrid: [],
    getLoadListAppChildCriterion: [],
    getCboAppChildCriterion: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F1030_GET_LOAD_FORM_GRID_SUCCESS:
            return { ...state, getLoadFormGrid: action.data };
        case types.W39F1030_GET_LOAD_LIST_APP_CHILD_CRITERION_SUCCESS:
            return { ...state, getLoadListAppChildCriterion: action.data };
        case types.W39F1030_GET_CBO_APP_CHILD_CRITERION_SUCCESS:
            return { ...state, getCboAppChildCriterion: action.data };
        default:
            return state;
    }
}
