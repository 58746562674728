/**
 * @copyright 2021 @ DigiNet
 * @author ANHTAI
 * @create 16/09/2021
 * @Example
 */

import { types } from "./W39F3035_actions";
import Config from "../../../config";
import _ from "lodash";

const initialState = {
    dataGrid: []
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case types.W39F3035_GET_DATA_GRID_SUCCESS: {
            let dataGrid = action.data;
            if (!_.isEmpty(dataGrid)) {
                dataGrid = dataGrid.map(item => ({
                    ...item,
                    DateFormTo: `${Config.convertDate(item.DateFrom, "", "DD/MM/YYYY")} - ${Config.convertDate(item.DateTo, "", "DD/MM/YYYY")}`
                }));
            }
            return {
                ...state,
                dataGrid
            };
        }
        default:
            return state;
    }
}